import React from 'react';
import HubspotForm from 'react-hubspot-form';
import { EventTypes, connectFormToGTM, useDetectIntersectionForGTM } from '@src/utils/gtm';
import { appendTooltip } from '@commons/append-tooltip/appendTooltip';
import { LoadingSpinner } from '@commons/loading-spinner/LoadingSpinner';
import { privatePolicyInfo } from '@commons/append-tooltip/payload';

export function NewsletterForm({ runSetFormSubmitted, formId }) {
  const policyInfo = privatePolicyInfo.type4;

  const formRef = useDetectIntersectionForGTM({
    event: EventTypes.FormNewsletterView,
    newsletter_localization: 'single news',
  });

  return (
    <div ref={formRef} className="old-hubspot-form">
      <HubspotForm
        region="na1"
        portalId="5206705"
        formId={formId}
        loading={<LoadingSpinner />}
        onReady={() => appendTooltip('#subscribeNewsletterForm', policyInfo)}
        onFormReady={() => connectFormToGTM('#subscribeNewsletterForm', 'single news')}
        onSubmit={() => undefined}
        onFormSubmitted={() => {
          runSetFormSubmitted();
        }}
      />
    </div>
  );
}
