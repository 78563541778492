import React, { useMemo } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { get } from 'lodash';

import { getPostDate } from '@src/utils/calculations';

import * as styles from './recent-news-section.module.scss';

export default function RecentNewsSection() {
  const gatsbyRecentBlog = useStaticQuery(graphql`
    query RecentNewsQuery {
      allMarkdownRemark(
        filter: { frontmatter: { layout: { eq: "news" } } }
        sort: { fields: frontmatter___date, order: DESC }
        limit: 1
      ) {
        edges {
          node {
            internal {
              content
            }
            frontmatter {
              title
              layout
              path
              date
            }
          }
        }
      }
    }
  `);
  const {
    frontmatter: { title, path, date },
  } = get(gatsbyRecentBlog, 'allMarkdownRemark.edges[0].node');

  const postDate = useMemo(() => getPostDate(date), []);
  return (
    <div className={styles.recentNewsContainer}>
      <div className={styles.singleRecentNews}>
        <p className="data">{postDate}</p>
        <Link to={path}>
          <strong> {title} </strong>
        </Link>
      </div>
    </div>
  );
}
