// extracted by mini-css-extract-plugin
export var consent = "newsletter-contact-form-module--consent--38aef";
export var container = "newsletter-contact-form-module--container--1e3a8";
export var containerForCross = "newsletter-contact-form-module--container-for-cross--f7ff6";
export var description = "newsletter-contact-form-module--description--bd30c";
export var formContainer = "newsletter-contact-form-module--form-container--5f47c";
export var formField = "newsletter-contact-form-module--form-field--6065a";
export var formInput = "newsletter-contact-form-module--form-input--0f500";
export var formTitle = "newsletter-contact-form-module--form-title--58fe0";
export var oldForm = "newsletter-contact-form-module--old-form--07228";
export var policy = "newsletter-contact-form-module--policy--fe085";
export var resetForm = "newsletter-contact-form-module--reset-form--6c877";
export var rodo = "newsletter-contact-form-module--rodo--98393";
export var submitBtn = "newsletter-contact-form-module--submit-btn--9f987";
export var successMsg = "newsletter-contact-form-module--success-msg--a2745";
export var thanksBannerContainer = "newsletter-contact-form-module--thanks-banner-container--2b7d6";
export var thanksImage = "newsletter-contact-form-module--thanks-image--4b0a5";
export var thanksSubtitle = "newsletter-contact-form-module--thanks-subtitle--62a9c";
export var thanksTitle = "newsletter-contact-form-module--thanks-title--ab0d4";