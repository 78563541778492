import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@src/layouts';

import NewsSingleHeader from './components/news-single-header/NewsSingleHeader';
import NewsSingleContent from './components/news-single-content/NewsSingleContent';

export default function newsTemplate(props) {
  const {
    markdownRemark: {
      frontmatter: { title, date, newsHeaderImage, seoTitle, seoDescription, featuredImage },
      html,
    },
  } = props.data;
  return (
    <Layout {...props}>
      <NewsSingleHeader thumbnail={newsHeaderImage.publicURL} />
      <NewsSingleContent
        html={html}
        date={date}
        title={title}
        seoTitle={seoTitle}
        seoDescription={seoDescription}
        featuredImage={featuredImage.publicURL}
      />
    </Layout>
  );
}

export const singleNewsQuery = graphql`
  query SingleNewsByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        date
        path
        title
        featuredImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          extension
          publicURL
        }
        thumbnail {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          extension
          publicURL
        }
        seoTitle
        seoDescription
        newsHeaderImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          extension
          publicURL
        }
      }
      html
    }
  }
`;
