import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import { NewsletterForm } from '@commons/forms/newsletter-form/NewsletterForm';

import backgroundImage from '@images/newsletterBanner/newsletter-background-img.svg';
import cross from '../images/cross.png';

import { formData } from './payload';

import * as styles from './newsletter-contact-form.module.scss';
import './contact-form.scss';
import './hubspot-embedded-form.scss';
import './hubspot-embedded-old-form.scss';

export const NewsletterContactForm = ({
  onClickHandler,
  setShowBanner,
  oldFormat = false,
  classNames: { containerWithForm } = {},
}) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { mainTitle, description, thankYouTitle, thankYouNote } = formData;

  function runSetFormSubmitted() {
    setFormSubmitted(true);
  }

  useEffect(() => {
    setShowBanner && formSubmitted && setShowBanner(false);
  }, [formSubmitted]);

  return (
    <div
      id="subscribeNewsletterForm"
      className={cx(styles.container, containerWithForm, 'newsletter-hubspot-form', {
        [styles.thanksBannerContainer]: formSubmitted,
        [cx('old-form', styles.oldForm)]: oldFormat,
      })}
    >
      {!formSubmitted ? (
        <>
          <h2 className={styles.formTitle}>{mainTitle}</h2>
          {oldFormat && <p className={styles.description}>{description}</p>}
          <NewsletterForm runSetFormSubmitted={runSetFormSubmitted} formId="c923975c-c93f-457d-8c28-402d4a54aad5" />
        </>
      ) : (
        <>
          <h3 className={styles.thanksTitle}>{thankYouTitle}</h3>
          <p className={styles.thanksSubtitle}>{thankYouNote}</p>
          <img loading="lazy" src={backgroundImage} alt="Bottom image" className={styles.thanksImage} />
        </>
      )}
      <div className={styles.containerForCross}>
        <img src={cross} alt="close the form" className={styles.crossIcon} onClick={onClickHandler} />
      </div>
    </div>
  );
};
