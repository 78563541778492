import React, { useState } from 'react';
import cx from 'classnames';

import { Overlay } from '@commons/overlay/Overlay';
import { NewsletterContactForm } from './newsletter-contact-form/NewsletterContactForm';

import { bannerData } from './payload';

import * as styles from './newsletter-banner.module.scss';

export const NewsletterBanner = ({ classNames: { mainContainer, containerWithForm } = {} }) => {
  const [showForm, setShowForm] = useState(false);
  const { title, subtitle } = bannerData;
  const [showBanner, setShowBanner] = useState(true);

  const hideForm = () => {
    setShowForm(false);
    setShowBanner(true);
  };

  return (
    <div className={cx(styles.mainContainer, mainContainer)}>
      <div
        className={cx(styles.subscribeContainer, !showBanner && styles.hideBanner)}
        onClick={() => setShowForm(true)}
      >
        <div className={styles.innerContainer}>
          <span className={styles.subscribeTitle}>{title}</span>
          <p className={styles.subscribeSubtitle}>{subtitle}</p>
          <div className={styles.imageContainer}></div>
        </div>
      </div>

      {showForm && (
        <>
          {showBanner && <Overlay />}
          <NewsletterContactForm
            onClickHandler={hideForm}
            setShowBanner={setShowBanner}
            classNames={{ containerWithForm: containerWithForm }}
          />
        </>
      )}
    </div>
  );
};
