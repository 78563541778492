// extracted by mini-css-extract-plugin
export var aside = "news-single-content-module--aside--9b36e";
export var bannerMainContainer = "news-single-content-module--banner-main-container--8d42b";
export var containerWithForm = "news-single-content-module--container-with-form--1d554";
export var data = "news-single-content-module--data--618e4";
export var innerWrapper = "news-single-content-module--inner-wrapper--2e882";
export var mainContent = "news-single-content-module--main-content--59772";
export var mainTitle = "news-single-content-module--main-title--080ad";
export var newsArticleSection = "news-single-content-module--news-article-section--959fa";
export var newsContainer = "news-single-content-module--news-container--61661";
export var newsletter = "news-single-content-module--newsletter--8b488";
export var outerWrapper = "news-single-content-module--outer-wrapper--dac69";
export var panel = "news-single-content-module--panel--cfb66";
export var previousNewsContainer = "news-single-content-module--previous-news-container--1b4af";