import infoIcon from '@images/golang-development/why-use-golang/pop-up/infoImg.svg';
import { appendJquery } from '@src/utils/appendJquery';

export const appendTooltip = (formId, privatePolicyInfo, icon = infoIcon) => {
  appendJquery();
  const formContainer = formId + ' .hs-form-booleancheckbox';
  const parentContainer = document.querySelector(formContainer);

  const tooltipContainer = document.createElement('details');
  tooltipContainer.setAttribute('class', 'tooltip');
  tooltipContainer.appendChild(createSumaryWithInfoIcon(icon));
  tooltipContainer.appendChild(createTooltipPopUp(privatePolicyInfo));
  parentContainer.appendChild(tooltipContainer);
  resetTextarea(formId);
};

const resetTextarea = (formId) => {
  const textarea = document.querySelector(formId + ' .hs-fieldtype-textarea[name="message"]');
  if (textarea) {
    textarea.value = '';
  }
};

export const appendTooltipToAsterisk = (formId, privatePolicyInfo, icon = infoIcon) => {
  appendJquery();
  const formContainer = formId + ' .hs-form-booleancheckbox .hs-form-required';
  const parentContainer = document.querySelector(formContainer);

  const tooltipContainer = document.createElement('details');
  tooltipContainer.setAttribute('class', 'tooltip');
  tooltipContainer.appendChild(createSumaryWithInfoIcon(icon));
  tooltipContainer.appendChild(createTooltipPopUp(privatePolicyInfo));
  parentContainer.appendChild(tooltipContainer);
};

const createSumaryWithInfoIcon = (icon) => {
  // create info icon
  const img = document.createElement('img');
  img.setAttribute('src', icon);
  img.setAttribute('alt', 'info');
  img.setAttribute('loading', 'lazy');

  //create summary container with img as a child
  const summary = document.createElement('summary');
  summary.setAttribute('class', 'tooltip-icon');
  summary.appendChild(img);

  return summary;
};

const createTooltipPopUp = (privatePolicyInfo) => {
  // create tooltip pop up with content
  const tooltipPopUp = document.createElement('div');
  tooltipPopUp.setAttribute('class', 'tooltip-popup');

  const tooltipContent = document.createElement('div');
  tooltipContent.setAttribute('class', 'tooltip-content');
  tooltipContent.appendChild(createParagraphWIthLink(privatePolicyInfo));
  tooltipContent.appendChild(createButton());
  tooltipPopUp.appendChild(tooltipContent);
  return tooltipPopUp;
};

const createParagraphWIthLink = (paragraphText) => {
  const paragraph = document.createElement('p');
  paragraph.innerHTML = paragraphText + ' For more information see our ';

  const hyperlink = document.createElement('a');
  hyperlink.setAttribute('href', 'https://codilime.com/privacy-policy/');
  hyperlink.innerHTML = 'Privacy Policy';
  hyperlink.setAttribute('rel', 'noopener');
  hyperlink.setAttribute('target', '_blank');
  paragraph.appendChild(hyperlink);
  paragraph.innerHTML += '.';
  return paragraph;
};

const createButton = () => {
  const button = document.createElement('button');
  button.setAttribute('class', 'button-ok');
  button.innerHTML = 'Ok, got it!';
  //close tooltip after click on button
  button.onclick = function (event) {
    event.preventDefault();
    event.target.closest('details').removeAttribute('open');
  };
  return button;
};
