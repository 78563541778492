import React, { useContext } from 'react';
import cx from 'classnames';

import useSiteMetadata from '@hooks/useSiteMetadata';

import { StandardSection } from '@commons/standard-section/StandardSection';
import { TopBannerContext } from '@context/TopBannerContext';

import * as styles from './news-single-header.module.scss';

export default function NewsSingleHeader({ thumbnail }) {
  const { siteURL } = useSiteMetadata();
  const { isBannerHidden } = useContext(TopBannerContext);
  return (
    <StandardSection
      className={cx(styles.section, !isBannerHidden && 'top-banner-margin')}
      style={{
        background: `url(${siteURL}${thumbnail}) no-repeat center/cover`,
      }}
    >
    </StandardSection>
  );
}
