import React, { useMemo } from 'react';
// import { graphql, useStaticQuery } from 'gatsby';

// import { parseGraphResultToArrayNoEdges } from '@src/utils/parsers';
import { getPostDate } from '@src/utils/calculations';

import useSiteMetadata from '@hooks/useSiteMetadata';

import SEO from '@commons/SEO';
import { StandardSection } from '@commons/standard-section/StandardSection';
// import { NewsPostBox } from '@commons/news-post-box/NewsPostBox';
import { HTMLRender } from '@commons/html-render/HTMLRender';
import { NewsletterBanner } from '@commons/newsletter-banner/NewsletterBanner';
import RecentNewsSection from '../recent-news-section/RecentNewsSection';

import * as styles from './news-single-content.module.scss';

export default function NewsSingleContent({ html, date, title, seoTitle, seoDescription, featuredImage }) {
  const { siteURL } = useSiteMetadata();
  // const theLatestNewsQueryResult = useStaticQuery(graphql`
  //   query latestTwoNewsInArray {
  //     allMarkdownRemark(
  //       filter: { frontmatter: { layout: { eq: "news" } } }
  //       sort: { fields: frontmatter___date, order: DESC }
  //     ) {
  //       nodes {
  //         frontmatter {
  //           layout
  //           title
  //           path
  //           date
  //           featuredImage {
  //             childImageSharp {
  //               gatsbyImageData(layout: FULL_WIDTH)
  //             }
  //             extension
  //             publicURL
  //           }
  //           thumbnail {
  //             childImageSharp {
  //               gatsbyImageData(layout: FULL_WIDTH)
  //             }
  //             extension
  //             publicURL
  //           }
  //         }
  //       }
  //     }
  //   }
  // `);

  const postDate = useMemo(() => getPostDate(date), []);
  // const latestNewsInArray = useMemo(() => parseGraphResultToArrayNoEdges(theLatestNewsQueryResult), []);

  // const currentPostDate = new Date(date).getTime();
  // const earlierNews = latestNewsInArray.filter((item) => {
  //   const arrayPostDate = new Date(item.date).getTime();
  //   return +arrayPostDate < +currentPostDate;
  // });

  return (
    <>
      <SEO
        title={title}
        seoTitle={seoTitle || title}
        description={seoDescription}
        featuredImage={siteURL + featuredImage}
      />
      <StandardSection
        className={styles.newsArticleSection}
        classNames={{ innerWrapper: styles.innerWrapper, outerWrapper: styles.outerWrapper }}
      >
        <div className={styles.mainContent}>
          <p className={styles.data}>{postDate}</p>
          <h1>{title}</h1>
          <article id="reading-progress-target">
            <HTMLRender content={html} />
          </article>
          {/* <div className={styles.previousNewsContainer}>
            {earlierNews.length > 0 && (
              <>
                <h3>Previous news</h3>
                <div className={styles.newsContainer}>
                  {earlierNews.slice(0, 2).map((value, index) => (
                    <NewsPostBox key={index} {...value} />
                  ))}
                </div>
              </>
            )}
          </div> */}
        </div>
        <aside className={styles.aside}>
          <div className={styles.panel}>
            <h3>Recent news</h3>
            <RecentNewsSection />
            <NewsletterBanner
              classNames={{ mainContainer: styles.bannerMainContainer, containerWithForm: styles.containerWithForm }}
            />
          </div>
        </aside>
      </StandardSection>
    </>
  );
}
